<template>
  <div class="-mx-5 md:mx-0 md:rounded-b-lg bg-gradient-to-b from-gray-200 to-gray-100 border border-gray-300">
    <div class="bg-gray-300 border-b border-gray-400 px-4 py-2 text-xs font-semibold text-gray-600 uppercase tracking-wide">
      Amount invoiced by month
    </div>
    <apexchart height="350" type="bar" :options="hoursOptions" :series="hoursSeries" />
  </div>
</template>

<script>
import { Util as U } from "@/util";
import VueApexCharts from "vue3-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  data() {
    return {
      hoursOptions: {
        chart: { toolbar: { show: false } },
        legend: { show: false },
        states: {
          active: { filter: { type: "none" } },
          hover: { filter: { type: "none" } },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#63b3ed",
            shadeTo: "dark",
            shadeIntensity: 0.4,
          },
        },
        xaxis: { categories: [] },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return U.formatNumber(Math.round(val));
            },
          },
        },
        plotOptions: { bar: { dataLabels: { position: "top" } } },
        dataLabels: {
          formatter: function (val) {
            return U.formatNumber(Math.round(val));
          },
          offsetY: 5,
          style: { fontSize: "9px", fontWeight: "bold" },
          background: {
            dropShadow: { enabled: true },
            enabled: true,
            foreColor: "#4a5568",
            padding: 2,
          },
        },
      },
      hoursSeries: [],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    monthNames: U.monthNames,
    async getData() {
      const { invoices } = await U.api({ url: "api/invoices" });
      const data = invoices
        .sort((a, b) => {
          const aDate = new Date(a.createdOn).getTime();
          const bDate = new Date(b.createdOn).getTime();
          return aDate - bDate;
        })
        .reduce((a, c) => {
          let year = parseInt(c.createdOn.substring(0, 4));
          let month = parseInt(c.createdOn.substring(5, 7));
          let day = parseInt(c.createdOn.substring(8));
          if (day <= 10) {
            month--;
            if (month == 0) {
              year--;
              month = 1;
            }
          }
          if (day < 10) {
            day = "0" + day;
          }
          if (month < 10) {
            month = "0" + month;
          }
          const key = U.formatYearMonth(`${year}-${month}-${day}`);
          const index = a.findIndex((x) => x.key === key);
          if (index === -1) {
            a.push({ key, total: 0 });
            a[a.length - 1].total += c.convertedAmount;
          } else {
            a[index].total += c.convertedAmount;
          }
          return a;
        }, []);
      const categories = data.map((x) => x.key);
      this.hoursSeries = [{ name: "Amount", data: data.map((x) => x.total) }];
      this.hoursOptions = {
        ...this.hoursOptions,
        xaxis: { ...this.hoursOptions.xaxis, categories },
        yaxis: { ...this.hoursOptions.yaxis },
      };
    },
  },
};
</script>
