<template>
  <div class="-mx-5 md:mx-0 md:rounded-b-lg bg-gradient-to-b from-gray-200 to-gray-100 border border-gray-300">
    <div class="bg-gray-300 border-b border-gray-400 px-4 py-2 text-xs font-semibold text-gray-600 uppercase tracking-wide">
      Hours logged by month
    </div>
    <apexchart height="350" type="bar" :options="hoursOptions" :series="hoursSeries" />
  </div>
</template>

<script>
import { Util as U } from "@/util";
import VueApexCharts from "vue3-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  data() {
    return {
      hoursOptions: {
        chart: { stacked: true, toolbar: { show: false } },
        legend: { onItemHover: false },
        tooltip: {
          x: {
            formatter(label, data) {
              const { dataPointIndex: i } = data;
              const sum = data.w.globals.series.reduce((a, c) => a + c[i], 0);
              return `${label} (${sum.toFixed(2)} hours)`;
            },
          },
        },
        states: {
          active: { filter: { type: "none" } },
          hover: { filter: { type: "none" } },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#63b3ed",
            shadeTo: "dark",
            shadeIntensity: 0.4,
          },
        },
        xaxis: { categories: [] },
        yaxis: { forceNiceScale: true, decimalsInFloat: 2 },
        dataLabels: {
          offsetY: 3,
          style: { fontSize: "9px", fontWeight: "bold" },
          background: {
            dropShadow: { enabled: true },
            enabled: true,
            foreColor: "#4a5568",
            padding: 2,
          },
        },
      },
      hoursSeries: [],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    monthNames: U.monthNames,
    async getData() {
      const [{ items }, { entries }, { projects }] = await U.apiAll([
        { url: "api/items" },
        { url: "api/entries" },
        { url: "api/projects" },
      ]);
      const series = [];
      const categories = [];
      entries.sort((a, b) =>
        a.loggedFor < b.loggedFor ? -1 : a.loggedFor > b.loggedFor ? 1 : 0
      );
      entries.forEach((e) => {
        const item = items.find((i) => i.uuid === e.itemUuid);
        const project = projects.find((p) => p.uuid === item.projectUuid);
        if (!series.some((s) => s.name === project.name)) {
          series.push({ name: project.name, data: [] });
        }
        const key = U.formatYearMonth(e.loggedFor);
        if (!categories.some((c) => c === key)) {
          categories.push(key);
        }
      });
      series.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      series.forEach((s) => {
        categories.forEach((c, index) => {
          let total = 0;
          entries.forEach((e) => {
            const item = items.find((i) => i.uuid === e.itemUuid);
            const project = projects.find((p) => p.uuid === item.projectUuid);
            const key = U.formatYearMonth(e.loggedFor);
            if (project.name === s.name && c === key) {
              total += e.minutes / 60;
            }
          });
          s.data[index] = total.toFixed(2);
        });
      });
      U.addMissingData(series, categories);
      this.hoursSeries = series;
      this.hoursOptions = {
        ...this.hoursOptions,
        xaxis: { ...this.hoursOptions.xaxis, categories },
        yaxis: { ...this.hoursOptions.yaxis },
      };
    },
  },
};
</script>
