<template>
  <div class="-mx-5 md:mx-0 md:rounded-b-lg bg-gradient-to-b from-gray-200 to-gray-100 border border-gray-300">
    <div class="bg-gray-300 border-b border-gray-400 px-4 py-2 text-xs font-semibold text-gray-600 uppercase tracking-wide">
      Tasks completed by month
    </div>
    <apexchart height="350" type="bar" :options="tasksOptions" :series="tasksSeries" />
  </div>
</template>

<script>
import { Util as U } from "@/util";
import VueApexCharts from "vue3-apexcharts";
export default {
  components: { apexchart: VueApexCharts },
  data() {
    return {
      tasksOptions: {
        chart: { stacked: true, toolbar: { show: false } },
        legend: { onItemHover: false },
        tooltip: {
          x: {
            formatter(label, data) {
              const { dataPointIndex: i } = data;
              const sum = data.w.globals.series.reduce((a, c) => a + c[i], 0);
              return `${label} (${sum} tasks)`;
            },
          },
        },
        states: {
          active: { filter: { type: "none" } },
          hover: { filter: { type: "none" } },
        },
        theme: {
          monochrome: {
            enabled: true,
            color: "#63b3ed",
            shadeTo: "dark",
            shadeIntensity: 0.4,
          },
        },
        xaxis: { categories: [] },
        yaxis: { forceNiceScale: true, decimalsInFloat: 2 },
        dataLabels: {
          offsetY: 3,
          style: { fontSize: "9px", fontWeight: "bold" },
          background: {
            dropShadow: { enabled: true },
            enabled: true,
            foreColor: "#4a5568",
            padding: 2,
          },
        },
      },
      tasksSeries: [],
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    monthNames: U.monthNames,
    async getData() {
      const [{ items }, { projects }] = await U.apiAll([
        { url: "api/items?done=true" },
        { url: "api/projects" },
      ]);
      const series = [];
      const categories = [];
      items.sort((a, b) =>
        a.doneOn < b.doneOn ? -1 : a.doneOn > b.doneOn ? 1 : 0
      );
      items.forEach((i) => {
        const project = projects.find((p) => p.uuid === i.projectUuid);
        if (!series.some((s) => s.name === project.name)) {
          series.push({ name: project.name, data: [] });
        }
        const key = U.formatYearMonth(i.doneOn);
        if (!categories.some((c) => c === key)) {
          categories.push(key);
        }
      });
      series.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      series.forEach((s) => {
        categories.forEach((c, index) => {
          let total = 0;
          items.forEach((i) => {
            const project = projects.find((p) => p.uuid === i.projectUuid);
            const key = U.formatYearMonth(i.doneOn);
            if (project.name === s.name && c === key) {
              total++;
            }
          });
          s.data[index] = total;
        });
      });
      U.addMissingData(series, categories);
      this.tasksSeries = series;
      this.tasksOptions = {
        ...this.tasksOptions,
        xaxis: { ...this.tasksOptions.xaxis, categories },
        yaxis: { ...this.tasksOptions.yaxis },
      };
    },
  },
};
</script>
