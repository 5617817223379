<template>
  <div id="reports-page">
    <app-box width="large">
      <template #legend>Reports</template>
      <template #title>Reach your goals</template>
      <template #intro>
        On this page you can view the results of your work to see if you're reaching your goals.
      </template>
      <div class="-mb-8 md:mb-0" :class="minimal ? ['pt-2 -mt-28 md:-mt-20'] : []">
        <app-hours-logged class="md:mb-6" />
        <app-tasks-completed class="md:mb-6" />
        <app-amount-invoiced />
      </div>
    </app-box>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { Util as U } from "@/util";
import AmountInvoiced from "./reports/AmountInvoiced";
import HoursLogged from "./reports/HoursLogged";
import TasksCompleted from "./reports/TasksCompleted";
export default {
  name: "Reports",
  components: {
    appAmountInvoiced: AmountInvoiced,
    appHoursLogged: HoursLogged,
    appTasksCompleted: TasksCompleted,
  },
  computed: {
    ...mapGetters(["loggedIn"]),
    ...mapState(["minimal"]),
  },
  async created() {
    if (U.redirect(this.loggedIn)) return this.$router.push("/");
    this.$store.commit("state", { key: "loading", value: true });
    window.setTimeout(() => {
      this.$store.commit("state", { key: "loading", value: false });
    });
  },
};
</script>
